@import 'partials/partials';

.section {
  position: relative;

  &-bg {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 49.4vw;
    background-image: url('../svg/section-bg.svg');
    background-repeat: no-repeat;
    background-size: cover;
    z-index: -1;

    &__bottom {
      bottom: 0;
      top: auto;
    }

    &--images {
      background-image: url('../svg/section-bg-alt.svg');
    }

    &--content {
      background-image: none;
    }
  }

  h2 {
    @include fontW(900, $primary-font);
    @include fontSize(xl);
    margin-top: 100px;
    color: $tdPink;

    @include MQ(sDown) {
      @include fontSize(xl-m);
    }
    @include MQ(xxlUp) {
      margin-top: 200px;
    }
  }

  h3 {
    @extend .h3;
    text-align: center;
  }

  h4 {
    @extend .h4;
    color: $tdRed !important;
    text-align: center;
  }

  p {
    @extend .p;
  }

  .image {
    width: 62%;
    @extend .br-primary;
    @extend .bs-primary;
  }

  &--spacer {
    background-color: $w;
    max-height: 75px;
  }

  &--steps {
    .steps-icon {
      font-size: 100px;
    }
  }

  &--cta {
    background-color: $tdRed;

    h2 {
      text-align: center;
      color: $offWhite;
    }

    &-light {
      background-color: $w;

      h2 {
        text-align: center;
        color: $tdPink3;
      }
    }
  }

  &--images {
    h2 {
      color: $tdBlk;
    }
    p {
      color: $tdBlk;
    }

    img {
      width: 100%;
    }
  }

  &--text {
    h2 {
      color: $tdRed;
    }

    a {
      @extend .p;
      color: $tdRed !important;
      text-decoration: none;

      @include MQ(mqH) {
        &:hover {
          color: $tdPink3 !important;
        }
      }
    }
  }

  &--content {
    h2 {
      margin-bottom: 15px;
    }
    h3 {
      margin: 30px 0;
    }
    h4 {
      @extend .h4;
      color: $tdRed3 !important;
      font-weight: 700 !important;
      margin: 30px 0 5px;
      font-size: 18px !important;
    }
    ul {
      padding: 15px 30px;
    }
    li {
      list-style-type: disc;
      color: $tdRed3;
      margin: 15px 0;
    }
    a {
      color: $tdRed3;
      text-decoration: none;

      @include MQ(mqH) {
        &:hover {
          color: $tdPink3;
        }
      }
    }
  }
}
