.row {
  width: calc(100% - 300px);

  @include MQ(XXXL) { width: calc(100% - 600px); }
  @include MQ(XXL) { width: calc(100% - 400px); }
  @include MQ(L) { width: calc(100% - 200px); }
  @include MQ(M) { width: calc(100% - 100px); }
  @include MQ(S) { width: calc(100% - 100px); }
  @include MQ(XS) { width: calc(100% - 50px); }

  &-full { width: 100%; }
}

.col {
  
  &-10 { width: 83%; }
  &-11 { width: 91.6%; }
  &-6 { width: 50%; }
  &-3 { width: calc(100% / 3); }

  &--full-m {
    @include MQ(S) { width: 100%; }
    @include MQ(XS) { width: 100%; }
  }
}

.container { @include flexBox(flex, flex-start, center, column); }
