.header {
  left: 0;
  right: 0;
  padding-bottom: 5px;
  position: fixed;
  will-change: transform, box-shadow;
  transition: transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
  background-color: rgba($tdPink, 1);
  z-index: 10;
  @include boxShadow(0);

  &--hidden {
    transform: translateY(-78px);
  }

  &--visible {
    transform: translateY(0);
    background-color: rgba($tdPink, 0.97);
    @include boxShadow(primary);
  }
}

.navigation {
  @include flexBox(flex, space-between, center, row);
  @extend .row;
  margin: 36px auto 0;

  &-list {
    @include flexBox(flex, flex-end, center, row);
  }

  &-logo {
    img {
      @include MQ(XS) {
        width: 120px;
      }
    }
  }

  &-item {
    margin-left: 15px;
    @include MQ(XS) {
      margin-left: 12px;
    }

    &-link {
      color: $w;
      text-decoration: none;
      @include fontW(700, $primary-font);
      @include fontSize(xs);

      &:hover {
        color: $tdPink2;
      }
    }
  }
}
