// the 'Drobe Colours
$tdRed: #dd1f36;
$tdRed1: #fce1e4;
$tdRed2: #f5939f;
$tdRed3: #f26d7d;
$tdRed4: #ea485b;
$tdPink: #e84999;
$tdPink1: #fdebf3;
$tdPink2: #f9cbdf;
$tdPink3: #f29fc5;
$tdPink4: #eb6faa;
$tdBlk: #353333;
$tdDGrey: #6e6b6a;
$tdLGrey: #cac3c4;
$tdGrey: #a2999a;
$tdOffWhite: #fafafa;
$offWhite: #f6f2f2;
$w: #ffffff;
$b: #000000;

// Fonts
$primary-font: 'niveau-grotesk', sans-serif;
