.cookie-consent {
  @include gradient();
  @include flexBox(flex, space-between, center);
  position: fixed;
  bottom: 0;
  width: 100vw;
  box-sizing: border-box;
  padding: 5px 10px;
  z-index: 1000;

  p {
    @include fontW(400, $primary-font);
    @include fontSize(xs);
    color: $w;
    flex: 1;

    a {
      color: $w;
    }
  }
}
