html, body, div, span, applet, object, iframe, h1, h2, h3, h4, h5, h6, p, blockquote, pre, a, abbr,
acronym, address, big, cite, code, del, dfn, em, img, ins, kbd, q, s, samp, small, strike, strong,
sub, sup, tt, var, u, i, dl, dt, dd,   ul, li,  fieldset, form, label, legend, table, caption,
tbody, tfoot, thead, tr, th, td, article, aside, canvas, details, embed, figure, figcaption, footer,
header, hgroup, menu, nav, output, ruby, section, summary, time, mark, audio, video {
  margin: 0; padding: 0; border: 0;
}
/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure, footer, header, hgroup, menu, nav, section {
  display: block;
}
/* ul { list-style: none; } */
blockquote, q { quotes: none; }
blockquote:before, blockquote:after, q:before, q:after { content: none; }
table { border-collapse: collapse; border-spacing: 0; }
ul, ol, li { list-style:none; }
a {
  outline:0 none;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  transition: color 200ms ease-in-out 0s;
}
a:hover {
  -webkit-transition: color 300ms ease-in-out 0s;
  -moz-transition: color 300ms ease-in-out 0s;
  -o-transition: color 300ms ease-in-out 0s;
  -ms-transition: color 300ms ease-in-out 0s;
  transition: color 300ms ease-in-out 0s;
}
input { -webkit-tap-highlight-color: rgba(0, 0, 0, 0); }
input:focus { border:0 none; outline:0 none; }
input[type="submit"], input[type="button"] {
  -webkit-transition: color 200ms ease-in-out 0s;
  -moz-transition: color 200ms ease-in-out 0s;
  -o-transition: color 200ms ease-in-out 0s;
  -ms-transition: color 200ms ease-in-out 0s;
  transition: color 200ms ease-in-out 0s;
}
input[type="submit"]:hover, input[type="button"]:hover {
  -webkit-transition: color 300ms ease-in-out 0s;
  -moz-transition: color 300ms ease-in-out 0s;
  -o-transition: color 300ms ease-in-out 0s;
  -ms-transition: color 300ms ease-in-out 0s;
  transition: color 300ms ease-in-out 0s;
}
input::-moz-focus-inner /*Remove button padding in FF*/ {
  border: 0;
  padding: 0;
}
