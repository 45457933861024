.shape {
  position: absolute;
  z-index: 5;

  @include MQ(lDown) { display: none; }

  &-one {
    left: -90px;
    bottom: -180px;
  }

  &-two {
    right: -100px;
    top: -80px;
  }

  &-three {
    left: -150px;
    top: -150px;
  }

  &-four {
    right: -20px;
    top: -50px;
  }

  &-five {
    left: -250px;
    bottom: -130px;
  }
}