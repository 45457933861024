@import "partials/partials";

.main-banner {
  width: 100vw;
  position: relative;
  color: $w;

  &-bg {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 46.34vw;
    background-image: url('../svg/main-banner.svg');
    background-repeat: no-repeat;
    background-size: cover;
    z-index: -1;
    @include MQ(lDown) { height: 80vh; }
  }

  .content {
    margin-top: 150px;
    @include MQ(sDown) { margin-top: 100px; }
    @include MQ(xxlUp) { margin-top: 200px; }
  }

  h1 {
    @include fontW(700, $primary-font);
    @include fontSize(xl);
    margin-bottom: 26px;

    @include MQ(sDown) { @include fontSize(xl-m); }
  }

  p {
    @include fontW(400, $primary-font);
    @include fontSize(s);
    line-height: 32px;
    margin-bottom: 26px;
  }

}

.stores {
  justify-content: flex-start;
  @include MQ(sDown) { justify-content: center; }
}