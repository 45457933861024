@import 'partials/partials';

.footer {
  position: relative;

  &-bg {
    @include bg-image('../svg/footer-bg.svg', 39);
    @include MQ(lDown) {
      display: none;
    }
  }

  &-info {
    @include MQ(lDown) {
      order: 1;
      @include flexBox(flex, center, center, column);
    }
  }

  &-logo {
    margin-bottom: 50px;

    @include MQ(xxlUp) {
      margin-bottom: 150px;
    }
    @include MQ(M) {
      max-width: 200px;
      margin-bottom: 0px;
    }
  }

  &-rights {
    margin-top: 100px;

    @include MQ(lDown) {
      color: $tdDGrey !important;
      margin-top: 30px;
      margin-bottom: 100px;
    }
  }

  &-nav {
    margin-top: 100px;
    @include MQ(xxlUp) {
      margin-top: 150px;
    }
    @include MQ(lDown) {
      margin-top: 40px;
    }

    &-list {
      margin-bottom: 50px;
    }

    &-item {
      a {
        @extend .p;
        color: $offWhite;
        text-decoration: none;

        @include MQ(mqH) {
          &:hover {
            color: $tdPink;
          }
        }
      }
    }

    &-title {
      @extend .h4;
      font-weight: 700 !important;
      margin-bottom: 15px;
      letter-spacing: 0.6px;
      @include MQ(lDown) {
        color: $tdRed !important;
      }
    }
  }

  p {
    @include fontW(400, $primary-font);
    @include fontSize(s);
    color: $offWhite;
  }
}
